import { showAccessRequestModalDispatcher } from "../redux/action/UIActions";
import request from "superagent";
import {
  processServiceResponse,
  logDebug,
  getAuthHeaders,
  getBaseUrl,
} from "./ServiceUtil";

export default class FetchListService {
  fetchList(
    dispatch,
    getState,
    listName,
    parentEntityTypeName,
    parentEntityId,
    queryObject = {}
  ) {
    var useMock = false;
    return new Promise(async (resolve, reject) => {
      //Don't put account in the URL because that will be determined from the hostname:
      let fetchListUrl = `${getBaseUrl(getState)}/api/v1`;
      if (parentEntityTypeName && parentEntityId) {
        fetchListUrl = `${fetchListUrl}/${parentEntityTypeName}/${parentEntityId}/${listName}`;
      } else if (parentEntityTypeName) {
        fetchListUrl = `${fetchListUrl}/${parentEntityTypeName}/${listName}`;
      } else {
        fetchListUrl = `${fetchListUrl}/${listName}`;
      }
      const authHeaders = await getAuthHeaders(getState);
      logDebug("fetchList", { fetchListUrl });
      request
        .get(fetchListUrl)
        // .timeout({
        //   response: 2000, // Wait up to 2 seconds for the server to start sending data
        //   deadline: 5000  // Wait up to 5 seconds for the entire request to complete
        // })
        .set(authHeaders)
        .set("Accept", "application/json")
        .query(queryObject)
        .end((error, response) => {
          processServiceResponse(
            dispatch,
            getState,
            error,
            response,
            resolve,
            reject
          );
          if (
            response &&
            response.statusCode === 403 &&
            response.body &&
            response.body.errorCode === "NoAccess"
          ) {
            dispatch &&
              dispatch(
                showAccessRequestModalDispatcher(
                  parentEntityTypeName,
                  parentEntityId,
                  queryObject
                )
              );
          }
        });
    });
  }
}

export var fetchListService = new FetchListService();
